import React, { useEffect, useState } from 'react';
import { getHash } from '~/utils';
import { globalStore } from '~/store';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
const getClientHints = async () => {
  const clientHints = (navigator as any).userAgentData
    ? await (navigator as any).userAgentData.getHighEntropyValues(['architecture', 'model', 'platform', 'platformVersion', 'uaFullVersion', 'fullVersionList'])
    : {};
  return clientHints;
};

const ClientHints: React.FC = () => {
  const [clientHints, setClientHints] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const hints = await getClientHints();
      const hash = await getHash(hints);
      const data = { hash, data: hints };
      setClientHints(data);
      globalStore.set({ ...globalStore.get(), client_hints: data });
    };
    fetchData();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        Client Hints <span className="text-sm border px-1">{clientHints?.hash.slice(0, 12)}</span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={clientHints} />
      </div>
    </div>
  );
};

export default ClientHints;
